import React, { useEffect } from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Navbar from "../components/_App/NavbarStyleFour";
import AdsBanner from "../components/ITStartup/AdsBanner";
import Features from "../components/ITStartup/Features";
import LandingBoxes from "../components/ITStartup/LandingBoxes";
import RecentWorks from "../components/Common/RecentWorks";
import ContactForm from "../components/Contact/ContactForm";
import Footer from "../components/_App/Footer";
import { logEvent, logPage } from '../analytics/analytics';
import { MessengerChat } from 'react-messenger-chat-plugin';
import '../assets/css/bootstrap.min.css';
import GoogleManager from "../utils/GoogleManager";

const IndexPage = () => {
    const [isMobile, setMobile] = React.useState(false);

    logPage('HOME');
    logEvent('test evento');

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    React.useEffect(() => {
        window.addEventListener("resize", () => handleResize());
        handleResize();
        return () => {
            window.removeEventListener("resize", () => handleResize());
        }
    },[])


    return (
    <Layout>
        <GoogleManager />
        <SEO title="HOME" />
        <Navbar />
        <div className={isMobile ? "bg-landingMobile position-relative" : 'bg-landing position-relative'}>
            <div className="landingAbsolute">
                <h1 className='TTfors p-0 m-0' style={{ color: 'white', textShadow: '0px 2px 20px black', fontSize: '4vw' }}>SEI A POCHI CLICK DAL CAMBIARE IL TUO BUSINESS. <span className="BrinnanThin" style={{color:'#3eff3e'}}>IN MEGLIO</span></h1>
                {
                    !isMobile ? <p style={{ color: 'white', textShadow: '0px 2px 20px black', fontSize: '1.3vw' }}>Supera i limiti del tuo attuale gestionale. Scegli una soluzione personalizzata per il tuo Business.</p> : null
                }
            </div>
            {
                isMobile ? <div style={{position:'absolute', bottom:'7em', left:'50%', transform:'translate(-50%, -50%)', width:'80%', textAlign:'center'}}>
                    <p className="BrinnanThin" style={{ color: 'black', textShadow: '0px 2px 20px black', fontSize: '4vw' }}>Supera i limiti del tuo attuale gestionale. Scegli una soluzione personalizzata per il tuo Business.</p> 
                </div> : null
            }
            <button onClick={()=> window.location.href = '/contact'} className="buttonLanding">Scopri</button>
        </div>
        <LandingBoxes />
        <ContactForm />
        <Features />
        <RecentWorks />
        <Footer />
        <MessengerChat pageId="100118916110273" language={"it_IT"} />
    </Layout>
    )
    }


export default IndexPage;
