import React, { useEffect } from 'react'; 
import sendMailToAdmin from '../../services/sendMailToAdmin';
import ContactImg from "../../images/bollaBird.png";
import ombra from "../../images/ombra-telefoni.png";
import bgInvert from '../../images/api-nere.png';

const ContactForm = (props) => {

    const [nome, setNome] = React.useState(''); 
    const [mail, setMail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [oggetto, setOggetto] = React.useState('');
    const [testo, setTesto] = React.useState('');
    const [landing, setLanding] = React.useState(false);

    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const[success, setSuccess] = React.useState(false);

    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
        let url = window.location.href;
        if (url.includes('landing')) {
            setLanding(true);
        } else {
            setLanding(false);
        }
    }

    useEffect(() => {
        
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    const handleMailSend =  async () => {
        if (mail.trim() !== '' && nome.trim() !== '' && oggetto.trim !== '' && testo.trim() !== '') {

            await sendMailToAdmin('Contatto', mail, nome, phone, oggetto, testo);
            
            setSuccess(true);
            setError(false);
            setMail('');
            setNome('');
            setPhone('');
            setOggetto('');
            setTesto('');

            setTimeout(() => {
                gtag('event', 'conversion', {'send_to': 'AW-10927229662/4WcuCOnkvdIZEN6Vwdoo'});
                window.location.href = '/';
            }, 3000);
        } else {
            setErrorMessage('Attenzione, verifica di aver compilato tutti i campi obbligatori.');
            setError(true);

            setTimeout(() => {
                setSuccess(false);
                setError(false);
            }, 4000);
        }
        
    }

    return (
        <div ref={props.contactRef} className={`${landing ? "" : "bg-contact"}`}>
            <div className="page-title-area overflow-hidden" style={{ alignContent: 'center', textAlign: 'center', padding:0, marginTop:landing ? 0 : 160, paddingBottom:100}}>
                <div className="container">
                    <div className='container' style={{textAlign: 'start', marginTop:50}}>
                        <h2 style={{color:'rgb(49, 48, 206)', fontWeight: 'bold', marginBottom:'1.5rem'}} className='text-uppercase Varela'>Contattaci</h2>
                        <p className='BrinnanLight' style={{margin:0, fontSize:17}}>Saremo felici di assisterti per ogni tua esigenza!</p>
                    </div>

                    <div className="row justify-content-between">
                        {
                            isMobile && (
                                <div className="col-lg-5 col-md-12 position-relative">
                                    <img className='imgContact' src={ContactImg} alt="image" />
                                    {
                                        !landing && (
                                            <img src={ContactImg} alt="image" className='contactInvert' />
                                        )
                                    }
                                    
                                </div>
                            )
                        }
                        <div className={`col-lg-5 col-md-12 position-relative input_contact${isMobile ? ' container' : ''}`} style={{marginTop:isMobile ? 132 : 0}}>
                            <div className="row">
                                { error && <p style={{ color: '#E58080' }}>{errorMessage}</p> }
                                { success && <p style={{ color: '#72EB5F' }}>Messaggio inviato con successo, grazie!</p> }

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="name" 
                                            placeholder="Il tuo nome*" 
                                            className="form-control" 
                                            value={nome}
                                            onChange={event => setNome(event.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="email" 
                                            placeholder="Il tuo indirizzo e-mail*" 
                                            className="form-control"  
                                            value={mail}
                                            onChange={event => setMail(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="number" 
                                            placeholder="Il tuo numero di telefono" 
                                            className="form-control"  
                                            value={phone}
                                            onChange={event => setPhone(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input 
                                            type="text" 
                                            name="subject" 
                                            placeholder="Oggetto*" 
                                            className="form-control"  
                                            value={oggetto}
                                            onChange={event => setOggetto(event.target.value)}
                                        /> 
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea 
                                            style={{ resize: 'none' }}
                                            name="text" 
                                            cols="30" 
                                            rows="5" 
                                            placeholder="Scrivi il tuo messaggio...*" 
                                            className="form-control"  
                                            value={testo}
                                            onChange={event => setTesto(event.target.value)}
                                        /> 
                                    </div>
                                </div>
                                <div className={`col-lg-12 col-sm-12 buttonContact${isMobile ? ' justify-content-center d-flex' : ' text-start'}`}>
                                    <button style={{background:'#8CCDB0'}} className="btn btn-primary" onClick={() => handleMailSend()}>INVIA</button>
                                </div>
                                {
                                    (!isMobile && !landing) && (
                                        <div style={{position:'absolute', bottom:-84, left:12, width:118, height:51, background:'#8CCDB0', opacity:0.5, borderRadius:'6px'}} />
                                    )
                                }
                            </div>
                        </div>
                        {
                            !isMobile && (
                                <div className="col-lg-5 col-md-12 position-relative">
                                    <img className='imgContact' src={ContactImg} alt="image" />
                                    {
                                        !landing && (
                                            <img src={ContactImg} alt="image" className='contactInvert' />
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    !landing && (
                        <>
                            <img src={ombra} className='ombraContact' alt="" />
                            <img src={bgInvert} className='bgInvertContact' alt="" />
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default ContactForm;  