import React, { useEffect } from 'react';
// import { Link } from "gatsby";

import img from '../../images/coding.png';
import imgMobile from '../../images/coding-mobile.png';
import Dev from '../../images/homepage_dev.png';
import Fader from './Fader';

const MainBanner = () => {

    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
		<div className="main-banner">
			<div className="d-table">
				<div className="d-table-cell" style={{ backgroundSize: 'cover' }}>
                { isMobile ? (
                        <img style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', objectFit: 'cover', zIndex: -2 }} src={imgMobile} autoPlay muted loop playsInline />
                    ) : (
                        <img style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', objectFit: 'cover', zIndex: -2 }} src={img} autoPlay muted loop playsInline />
                    )
                }
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#00000066', zIndex: -1 }} />
                    <div className="container" style={{ overflow: 'hidden' }}>
                        <div className="row h-100  align-items-center">
                            <div className="col-lg-10">
                                <div className="hero-content" style={{ marginTop: 60 }}>
                                    <h1 style={{ color: 'white', textShadow: '0px 2px 20px black', fontWeight: 'bold', fontFamily: `url(${require('../../assets/fonts/Nexa.ttf')})` }}>{"Sei a pochi CLICK dal cambiare il tuo business. ".toUpperCase()} <Fader text={"RAPIDAMENTE;PER SEMPRE;IN MEGLIO"} colors={"#ff4d4d;#F8B80B;#15f322"} /> </h1>
                                    
                                    <p className='banner-subtitle' style={{ color: 'white', textShadow: '0px 2px 10px black', fontFamily: `url(${require('../../assets/fonts/Proxima.ttf')})`, fontSize: 20 }}>Supera i limiti del tuo attuale gestionale. Scegli una soluzione personalizzata per il tuo Business.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 offset-lg-1" style={{ display: 'none' }}>
                                <div className="banner-image">
                                        
                                        <img 
                                            src={Dev} 
                                            className="animate__animated animate__fadeInDown animate__delay-0.1s" 
                                            alt="man" 
                                        />
                            
                                        <img 
                                            
                                        />
                                    
                                        <img 
                                            
                                        />
                                
                                        <img 
                                            
                                        />
                            
                                        <img 
                                            
                                        />
                            
                                        <img 
                                            
                                        />
                        
                                        <img 
                                            
                                        />
                            
                                        <img 
                                            
                                        />
                            
                                        <img 
                                            
                                        />
                        
                                        <img 
                                            
                                        />
                                
                                        <img 
                                        
                                        />
                                    
                                        <img 
                                            
                                        />
                        
                                        <img 
                                            
                                        />
                            
                                        <img 
                                            
                                        />
                                
                                        <img 
                                            
                                        />
                                </div>
                            </div>
                            
                        </div>

                    </div>
				</div>
			</div>
		</div>
    )
}

export default MainBanner