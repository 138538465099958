import React from 'react';
import * as Icon from 'react-feather';
import ico1 from '../../images/ico_1.png'
import ico2 from '../../images/ico_2.png'
import ico3 from '../../images/ico_3.png'
import ico4 from '../../images/ico_4.png'
import ico5 from '../../images/ico_5.png'
import ico6 from '../../images/ico_6.png'

const Features = () => {
    return (
        <div className="boxes-area" style={{ marginTop: 100 }}>
			<div className="container backgroundHome">	
				<div className="row justify-content-center">
					<h2 style={{marginBottom: '20px', fontFamily: `url(${require('../../assets/fonts/Nexa.ttf')})`, fontWeight: 'bold' }}>{"I nostri gestionali".toUpperCase()}</h2>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico1} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>Per essere competitivi e funzionali, i comuni gestionali non bastano più. Serve un sistema ad hoc, cucito sulla struttura e le necessità della tua azienda.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico2} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>La consultazione dei dati e la generazioni di report diventa decisamente più facile e veloce con un software custom. Inoltre, puoi tirare un respiro di sollievo per tutto ciò che riguarda normative privacy e possibili perdite di dati.</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico3} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>Un gestionale personalizzato ti permetterà di risparmiare tempo. Tempo che potrai investire nelle altre attività per il tuo business.</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row justify-content-center" style={{marginTop: '30px'}}>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico4} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>Ci basterà un attimo per sincronizzare i tuoi dati nel nuovo gestionale! Tutte le informazioni potranno finalmente essere gestite in modo funzionale. </p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico5} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>Gestisci fatture, pagamenti, preventivi, magazzino e tanto altro da un unico strumento. Tutto sarà a portata di click!</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 mt-4">
						<div className="single-box-landing">
							<div className='d-flex justify-content-center'>
								<div className="iconLanding m-4">
									<img src={ico6} alt="" />
								</div>
							</div>
							<div className='infoHome-landing'>
								<p>Troverai tutti i servizi in ordine, dai sistemi di automazione alla gestione contatti e anagrafiche clienti. Migliora l'intera organizzazione e facilita il lavoro dei tuoi dipendenti e collaboratori, così da coordinarsi nel miglior modo possibile.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Features;
